import {
    API_RESOLVER_QUERIES,
    AsyncFeatureInitializer,
    FEATURE_SYSTEM_API_NAME,
    getStarCoreEnvironment,
    IFeatureSystemApi,
    IModule,
    QueryMessage,
    TFeatureName
} from '@dreamcommerce/star_core';

import { enableElfProdMode } from '@ngneat/elf';
import { STOREFRONT_DYNAMIC_FEATURES_RESOLVERS } from '@storefrontApp/initializer/storefront_dynamic_features_mapper';
import { create } from 'rxjs-spy';
import { devTools } from '@ngneat/elf-devtools';
import { BASKET_LOCAL_STORE_NAME, BASKET_NAMES } from '@storefrontFeatures/basket/basket_constants';
import { PAGE_MANAGER_NAMES } from '@storefrontCoreFeatures/page_management/page_manager_constants';
import { PAGE_MANAGER_EVENTS } from '@storefrontCoreFeatures/page_management/turbo_message_names';

export class StorefrontAppInitializer extends AsyncFeatureInitializer {
    public static featureName = 'StorefrontAppInitializer';

    private _featureSystemApi: IFeatureSystemApi | undefined;
    private _loadedScriptModuleIds: string[] = [];

    public async init<M extends IModule>(): Promise<void> {
        if (process.env.NODE_ENV === 'production') {
            enableElfProdMode();
        }

        const { queryBus } = getStarCoreEnvironment();

        if (process.env.NODE_ENV !== 'production') {
            create();
            devTools();
            this._initLiveReload();
        }

        this._featureSystemApi = await queryBus.execute<QueryMessage<string>, IFeatureSystemApi>(
            new QueryMessage(API_RESOLVER_QUERIES.getApi, FEATURE_SYSTEM_API_NAME)
        );

        this._initDynamicFeatures(
            STOREFRONT_DYNAMIC_FEATURES_RESOLVERS.filter(({ autoInit }) => autoInit).map(({ featureName }) => featureName)
        );

        this._loadLazyLoadedFeatures();
        this._loadUserScripts();

        this.eventBus.on(PAGE_MANAGER_EVENTS.loaded, () => this._loadCustomModulesScripts());
    }

    private async _loadLazyLoadedFeatures(): Promise<void> {
        const preoloadEvents = ['keydown', 'mousemove', 'touchstart'];

        const loadTurboTimeout = setTimeout(() => {
            fn();
        }, 1000 * 10);

        const fn = () => {
            clearTimeout(loadTurboTimeout);

            preoloadEvents.forEach((eventName: string) => document.removeEventListener(eventName, fn));
            this._featureSystemApi?.registerDynamic(PAGE_MANAGER_NAMES.feature);
        };

        preoloadEvents.forEach((eventName: string) => document.addEventListener(eventName, fn));

        const hasBasketId = localStorage.getItem(`${BASKET_LOCAL_STORE_NAME}.basketId`);

        if (hasBasketId) {
            this._featureSystemApi?.registerDynamic(BASKET_NAMES.feature);
        }
    }

    private async _initDynamicFeatures(initializersToLoad: TFeatureName[]): Promise<void> {
        this._registerDynamicFeatures(initializersToLoad);
    }

    private _initLiveReload() {
        const $script = document.createElement('script');
        $script.src = 'https://livereload.docker.shoper.tech:443/livereload.js?snipver=1';
        document.head.appendChild($script);
    }

    private async _registerDynamicFeatures(initializersToLoad: TFeatureName[]): Promise<void> {
        this._featureSystemApi?.registerDynamic(initializersToLoad);
    }

    private _loadUserScripts(): void {
        const $scripts = [...document.querySelectorAll<HTMLScriptElement>('script[data-src]:not([data-script-module-id])')];
        $scripts.forEach(($script) => {
            $script.src = $script.dataset.src as string;
        });
    }

    private _loadCustomModulesScripts(): void {
        const $scripts = [...document.querySelectorAll<HTMLScriptElement>('script[data-src][data-script-module-id]')];

        $scripts.forEach(($script) => {
            const moduleId = $script.dataset.moduleId as string;

            const $module = document.querySelector(`[data-module-id="${moduleId}"]`);

            if ($module && this._loadedScriptModuleIds.indexOf(moduleId) === -1) {
                $script.src = $script.dataset.src as string;
                this._loadedScriptModuleIds.push(moduleId);
            }
        });
    }
}
